<template>
  <div
    class="tabs-container"
  >
    <div
      class="tab-item"
      :class="{active: tab.value == props.selectedTab}"
      v-for="tab in props.tabs"
      :key="tab.value"
      @click="selectTab(tab.value)"
    >
      {{ $t(tab.title) }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['tabs', 'selectedTab'],
  emits: ['changeTab'],
  setup(props, {emit}) {
    const selectTab = (value) => {
      emit('changeTab', value);
    }
    return {
      props,
      selectTab
    }
  }
}
</script>
