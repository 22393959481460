import PATHS from '@/app/route/PATHS.js';
import router from '@/app/route/route.js';

import shared from '@/shared';

const {Cashdesk} = shared.api;
const {getErrorCode} = shared.model;

const state = {
  balance_data: null,
};

const getters = {
  BALANCE(state) {
    return state.balance_data;
  }
};

const mutations = {
  BALANCE_DATA(state, data) {
    state.balance_data = data;
  }
};

const actions = {
  GET_BALANCE_DATA(context, data) {
    Cashdesk.balanceData(data)
    .then(data => {
      context.commit('BALANCE_DATA', data.data);
    })
    .catch(err => {
      if (getErrorCode(err) === '404') {
        router.push(PATHS.NOT_FOUND);
      }
    })
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
