import axios from 'axios';
//import * as HttpStatus from 'http-status-codes';
import { store } from '@/app/store/store';

// const baseURL = process.env.VUE_APP_RETAIL_GATEWAY_URL ? `${process.env.VUE_APP_RETAIL_GATEWAY_URL}/` : 'https://cashier-dashboard.pl-test.play-platform.com/'

const retailSystemInstance = axios.create({
  baseURL: `${process.env.VUE_APP_RETAIL_GATEWAY_URL}/`,
  timeout: 25000,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  },
});

retailSystemInstance.interceptors.request.use(config => {
  store.commit("SET_LOADER", true);
  const { sessionToken } = store.getters["cashierStore/AUTH"];
  if (sessionToken)
    config.headers.Authorization = `bearer ${ sessionToken }`;

  return config;
}, (error) => {
  store.commit("SET_LOADER", false);
  return Promise.reject(error);
});

retailSystemInstance.interceptors.response.use(response => {
  store.commit("SET_LOADER", false);
  store.commit("SET_LOADER_DISABLE", false);
  return response;
}, (error) => {
  store.commit("SET_LOADER", false);
  store.commit("SET_LOADER_DISABLE", false);
  return Promise.reject(error);
});

export default class Login {
  goLogin(data = {}) {
    return retailSystemInstance.post('bo-retail/bo/user-management/users:login', data)
  }
  cashDeskLogin() {
    return retailSystemInstance.post('bo-retail/retail-gateway/cashdesk:login')
  }
  tokenValidate() {
    return retailSystemInstance.get('bo-retail/auth/token:validate');
  }
}
