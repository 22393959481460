import shared from '@/shared';
const { SportData } = shared.api;
const { sportsbookFilterObject } = shared.model;

import { errorCodeObject } from "@/entities";

const state = {
  categories_list: [],
  selected_categories_id: null
};

const getters = {
  GET_CATEGORIES_LIST(state) {
    return state.categories_list
  },
  GET_SELECTED_CATEGORIES_ID(state) {
    return state.selected_categories_id
  }
};

const mutations = {
  SET_CATEGORIES_LIST(state, data) {
    state.categories_list = data;
  },
  SET_SELECTED_CATEGORIES_ID(state, id) {
    state.selected_categories_id = id;
  }
};

const actions = {
  GET_CATEGORIES_LIST(context, keys) {
    SportData.getCategories({
      filter: sportsbookFilterObject(keys),
      paging: {
        currentPage: 0,
        itemsPerPage: 100
      }
    })
      .then(({ data }) => {
        //categoryArr.value = resp.data.data;
        context.commit('SET_CATEGORIES_LIST', data.data)
      })
      .catch(err => {
        errorCodeObject(err);
      })
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
