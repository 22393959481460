import router from '@/app/route/route.js';
import shared from '@/shared';

const { CashdeskLogin, Login } = shared.api;
const { localstorageDelete, localstorageSet, clearAllSetTimeout } = shared.model

import i18n from '@/app/i18n.js';
const { d } = i18n.global;

import { loginUser } from './loginUser';
import { cashDeskLogin } from './cashDeskLogin';
import { homePageIs } from './homePageIs';
import { timeToLogout } from "./timeToLogout";

const state = {
  auth: {},
  user_data: {},
  shift_end: null,
};

const getters = {
  AUTH(state) {
      return state.auth;
  },
  USER_DATA(state) {
      return state.user_data;
  },
  SHIFT_END(state) {
      let timestamp = state.shift_end || +localStorage.getItem('shiftValidTo');
      return d(new Date(timestamp), 'time24')
  },
};

const mutations = {
  AUTH_DATA(state, auth) {
    state.auth = auth;
  },
  USER_DATA(state, data) {
    state.user_data = data;
  },
  SHIFT_END(state, timestamp) {
    state.shift_end = timestamp;
  }
};

const actions = {
   CHECK_SHIFT_INTERVAL(context, logoutDate) {
      const minute = 60000;
      let checkShiftInterval = setInterval(() => {
        let currentTimestamp = new Date().getTime();
        let minutesLeft = (logoutDate - currentTimestamp) / minute;

        timeToLogout(minutesLeft, checkShiftInterval, context, minute)
      }, 1000);
    },

  async LOGIN_CASHIER(context, data) {
    const respUserData = await loginUser(data, context);
    if (!respUserData) return;

    context.commit('AUTH_DATA', respUserData);

    const respCashDeskData = await cashDeskLogin();
    if (!respCashDeskData) return;

    let logoutDate = respCashDeskData.validTo;

    context.commit('SHIFT_END', logoutDate);
    context.commit('cashdeskDataStore/CHANGE_CASHDESK_DATA', respCashDeskData.cashdesk, { root: true })
    localstorageSet({
      auth: JSON.stringify(respUserData),
      currency: respCashDeskData.cashdesk.currency,
      shiftValidTo: logoutDate
    })

    await context.dispatch('TOKEN_VALIDATE');

    homePageIs(
      context.rootGetters['APP_TYPE'],
      respCashDeskData.cashdesk.id
    )

    await context.dispatch('CHECK_SHIFT_INTERVAL', logoutDate);
  },

  TOKEN_VALIDATE(context) {
    Login.tokenValidate()
      .then(resp => {
        context.commit('USER_DATA', resp.data);
      })
      .catch(() => {
        context.commit('AUTH_DATA', {});
        localstorageDelete(['auth']);
        router.push('/');
      })
  },

  async LOGOUT_CASHIER() {
    clearAllSetTimeout();

    await CashdeskLogin.goLogout();

    localstorageDelete(['auth', 'cashdesk_data', 'shiftValidTo', 'betId', 'lastPlacedBet'])

    await router.push('/');
  }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
