import axios from 'axios';
import config from '@/shared/api/config/config.js';

const retailBookingInstance = axios.create(config.init('bo-retail/ordering/public/bet-booking/'));

retailBookingInstance.interceptors.request.use(config.request.success, config.request.failure);

retailBookingInstance.interceptors.response.use(config.response.success, config.response.failure);

export default class Booking {
    getPreOrder(id) {
       return retailBookingInstance.get(`pre-order/${ id }`)
    }
}
