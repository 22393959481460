<script setup>
import { defineProps, defineEmits, onMounted } from 'vue';

const props = defineProps(['title', 'size']);
const emit = defineEmits(['close']);

const closeDialog = () => emit('close');

onMounted(() => {
  window.addEventListener('keydown', e => {
    if (e.keyCode === 27)
      closeDialog();
  })
})

</script>

<template>
  <div class="modal-container modal-container-dialog">
    <div :class="`modal-dialog-content-container modal-dialog-content-container-${props.size}`">
      <div class="modal-dialog-header">
        <h4>{{ props.title }}</h4>
        <div @click="closeDialog">
          <img alt="close" src="../../assets/close.svg" />
        </div>
      </div>
      <slot name="main"></slot>
    </div>
  </div>
</template>
