import axios from 'axios';
import config from '@/shared/api/config/config.js';

const reteilSystemInstance = axios.create(config.init('bo-retail/'));

reteilSystemInstance.interceptors.request.use(config.request.success, config.request.failure);

reteilSystemInstance.interceptors.response.use(config.response.success, config.response.failure);

export default class CMS {
    getLogoUrl() {
        return reteilSystemInstance.get('cms-go/v1/backoffice/logo?type=desktop-light');
    }
    getConfig() {
        return reteilSystemInstance.get('cms-go/v1/site/cms-config');
    }
}
