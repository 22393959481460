<script setup>
  import { onMounted, watch, computed, ref, nextTick } from 'vue';
  import { useStore } from 'vuex';
  import {OutcomeService} from "@atlas/core";

  const store = useStore();
  const showErrorModal = ref(false);

  const theme = computed(() => store.getters['cashdeskConfigStore/GET_THEME']);

  const getThem = () => {
    document.documentElement.classList.remove('dark', 'light');

    if (theme.value) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.add('light')
    }
  }

  getThem();

  watch(
    () => theme.value,
    () => {
      getThem();
  })

  const closeErrorModal = (code) => {
    showErrorModal.value = false;
    if (code === 401 || code === 403 || code === 400) {
      store.dispatch('cashierStore/LOGOUT_CASHIER');
      store.commit('oddsBasketStore/CLEAR_ODDS_BASKET');
    }
    nextTick(() => {
      store.commit('notificationsStore/CLEAR_ERROR_MODAL');
    })
  }

  onMounted(() => {
    if (localStorage['odds_basket'])
      store.commit('oddsBasketStore/LOCAL_ODDS_BASKET');

    if (localStorage['shiftValidTo']) {
      store.dispatch('cashierStore/CHECK_SHIFT_INTERVAL', +localStorage['shiftValidTo']);
    }
  })

  const errorData = computed(() => store.getters["notificationsStore/ERROR_DATA"]);

  watch(() => errorData.value, () => {
    if (errorData.value) {
      showErrorModal.value = true;
    }
  })


  const checkConnection = () => {
    store.commit('notificationsStore/ADD_ERROR_DATA', {
      body: 'Check your internet connection',
    });
  }

  addEventListener("offline", checkConnection);

  const loaderIsShown = computed(() => store.getters["LOADER_IS_SHOWN"]),
        loaderIsDisabled = computed(() => store.getters["LOADER_IS_DISABLED"]),
        infoMessage = computed(() => store.getters["notificationsStore/INFO_MESSAGE"]);
        //alert = computed(() => store.getters['notificationsStore/ALERTS_DATA']);

  const balanceData = computed(() => store.getters['balanceState/BALANCE']);

  watch(() => balanceData.value, () => {
    if (balanceData.value) {
      const cashdeskId = balanceData.value.id.split('.');
      cashdeskId.length = 2;
      const coId = cashdeskId.join('.');

      store.dispatch('tournamentsStore/SPORT_FORMS', {coId})
    }
  })

  const clearInfoMessage = () => {
    store.commit('notificationsStore/MESSAGE_INFO_MODAL', null)
  }

  if (window.NODE_ENV !== 'production') {
    let jPressed = false;
    const  keyHandler = (event) => {
      if (event.key === 'j') {
        jPressed = true;
      }
      if (event.key === 's' &&  jPressed) {
        localStorage['test_terminal']
          ? localStorage.removeItem('test_terminal')
          : localStorage.setItem('test_terminal', 'terminal');
        location.reload()
      }
    }
    window.addEventListener('keydown', keyHandler);
  }

  const oddsFormat = (odds) => {
    const oddsFormat = store.getters['cashdeskConfigStore/ODDS_FORMAT']
    return OutcomeService.formatOdds(odds, { oddsFormat })//, { zeroes }
  }

</script>

<template>
  <div id="canvas-logo" class="printed-logo"></div>
  <ui-error-popup v-if="showErrorModal"  @close="closeErrorModal" :code="errorData?.code">
    <template v-slot:modal-error-body>
      <template v-if="Array.isArray(errorData?.body)">
        <div
          v-for="error in errorData.body"
          :key="error"
        >
          <span>{{ error.message }}</span><br />
          <template v-if=" error.outcome">
            {{
              `${error.outcome.outcomeInfo.localizedEventName}- ${ error.outcome.outcomeInfo.localizedMarketName }: ${ oddsFormat(error.outcome.odds) }`
            }}
          </template>
        </div>
      </template>
      <template v-else>
        {{ errorData.body }}
      </template>
    </template>
  </ui-error-popup>

  <ui-loader v-if="loaderIsShown && !loaderIsDisabled" />

  <template v-if="infoMessage != null">
    <ui-successful-popup @ok="clearInfoMessage">
      <template #modal-popup-body>{{ infoMessage }}</template>
    </ui-successful-popup>
  </template>

  <router-view></router-view>
  <div :data-testid="$route.fullPath"></div>
</template>

<style>
input, button, select {
    box-shadow: none !important;
  }
 #app {
  height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   position: relative;
}
@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}
.printed-logo {
  width: 50px;
  height: 50px;
  overflow: hidden;
  position: absolute;
  top: -100%;
}
</style>
