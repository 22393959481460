import { ref, onBeforeUnmount } from 'vue';

export async function recursiveCallBack(func) {
  let timeoutId = ref(null);
  const recursive = async () => {
    await func();
    timeoutId.value = setTimeout( () => recursive(), 5000);
  }

  await recursive();

  onBeforeUnmount(() => {
    clearTimeout(timeoutId.value);
  });

  return timeoutId;
}
