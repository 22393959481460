import router from "@/app/route/route";
import PATHS from "@/app/route/PATHS";

export function homePageIs (appType, id) {
  switch (appType) {
    case 'terminal':
      router.push(PATHS.TERMINAL.replace(':id', id));
      break;
    default:
      router.push(PATHS.CASHDESK_ID.replace(':id', id))
  }
}
