<script setup>
import { defineProps, onUpdated, onMounted } from 'vue';

const props = defineProps({
  addCardHover: Boolean
});

const bodyId = `card-body-id-${ Math.floor(Math.random() * 10000)}`;
let bodyDiv;

onMounted(() => {
  bodyDiv = document.getElementById(bodyId)
})

onUpdated(() => {
  !bodyDiv?.childElementCount
    ? bodyDiv?.classList?.remove('card-body')
    : bodyDiv?.classList?.add('card-body');
})


</script>

<template>
  <div class="card-container">
    <div
      class="card-content-container"
      :class="{ 'add-base-hover': props.addCardHover }"
    >
      <div class="card-header">
        <slot name="card-header"></slot>
      </div>
      <div :id="bodyId" class="card-body">
        <slot name="card-body"></slot>
      </div>
    </div>
  </div>
</template>
