import axios from 'axios';
import config from '@/shared/api/config/config.js';

const publicApiInstance = axios.create(config.init('api'));

publicApiInstance.interceptors.request.use(config.request.success, config.request.failure);

publicApiInstance.interceptors.response.use(config.response.success, config.response.failure);

export default class PublicApi {
  getBetData(id) {
    return publicApiInstance.get(`bettor/sport/public/bet/${ id }`)
  }
}
