import shared from '@/shared';

import { sortedEventsList } from './sortedEventsList.js';

const { sportsbookFilterObject } = shared.model;

const { SportData } = shared.api;

const state = {
  events_list: null
}

const getters = {
  GET_EVENTS_LIST(state) {
    return state.events_list
  }
}

const mutations = {
  SET_EVENTS_LIST(state, eventsData) {
    if (eventsData) {
      const { data } = eventsData;
      state.events_list = {
        sortedEvents: sortedEventsList(data),
        eventsData: data
      }
    } else {
      state.events_list = null;
    }
  }
}


const actions = {
  async SPORT_LIST(context, keys) {
    const filter = sportsbookFilterObject(keys)
    const { data } = await SportData.getData({ filter });
    context.commit('SET_EVENTS_LIST', data);
    return data;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
