const findEvent = (data, basket) => {
  return {
    event: basket.find(id => id.event_id == data.item.event_id),
    eventIndex: basket.findIndex(id => id.event_id == data.item.event_id)
  }
}

const findMarket = (eventData, data) => {
  return {
    market: eventData.event.market.find(id => id.marketId == data.marketId),
    marketIndex: eventData.event.market.findIndex(id => id.marketId == data.marketId)
  }
}

export const deleteItemInBasket = (data, basket) => {
  try {
    const findEventData = findEvent(data, basket);
    const findMarketData = findMarket(findEventData, data);

    delete findMarketData.market.outcomes;
    findEventData.event.market.splice(findMarketData.marketIndex, 1);

    if (findEventData.event.market.length === 0) {
      basket.splice(findEventData.eventIndex, 1);
    }
  } catch(err) {
    console.log('err', err)
  }
}
