<template>
  <div class="modal-container">
    <div class="modal-popup-content-container">
      <div class="modal-popup-body">
        <slot name="modal-popup-body"></slot>
      </div>
      <div class="popup-btn-container">
        <uiButtonFactory :buttonFactoryConfig="buttonFactoryConfig" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import uiButtonFactory from "@/shared/newUI/ui-button-factory/uiButtonFactory.vue";

export default {
  components: {
    uiButtonFactory
  },
  props: ['data'],
  emits: ['y', 'n'],
  setup(props, { emit }) {
    const { t } = useI18n();

    return {
      buttonFactoryConfig: [
        {
          text: computed(() => t('no')),
          variant: 'btn-primary',
          func: () => {
            emit('n');
          }
        },
        {
          text: computed(() => t('yes')),
          variant: 'btn-brand',
          func: () => {
            emit('y');
          }
        },
      ]
    }
  }
}
</script>
