const sortOneData = (eventsObject)  => {
  return Object.values(eventsObject[0].events)
    .sort((a, b) => a.startTime - b.startTime)
}

export const sortedEventsList = (data) => {
  const objectToArray = Object.values(data);
  return objectToArray.length === 1
    ? sortOneData(objectToArray)
    : null
}
