import axios from 'axios';
import { computed } from 'vue';
import { store } from '@/app/store/store.js';
import config from '@/shared/api/config/config.js';



const sportsBookClient = axios.create(config.init('bo-retail/sportsbook/'));

sportsBookClient.interceptors.request.use(config.request.success, config.request.failure);

sportsBookClient.interceptors.response.use(config.response.success, config.response.failure);

const lang = computed(() => store.getters['cashdeskConfigStore/LANGUAGE']);

export default class SportData {
  getSportList(filter = {}) {
    return sportsBookClient.post(`sports?locale=${ lang.value }`, filter);
  }

  getCategories(filter = {}) {
    return sportsBookClient.post(`categories?locale=${ lang.value }`, filter)
  }

  getTournamentList(filter = {}) {
    return sportsBookClient.post(`tournaments?locale=${ lang.value }`, filter)
  }

  getTournament(filter = {}) {
    return sportsBookClient.post(`tournaments?locale=${ lang.value }`, filter)
  }

  getConfig() {
    return sportsBookClient.get('settings');
  }

  getData(filter = {}) {
    return sportsBookClient.post(`events?locale=${ lang.value }`, filter);
  }

  getEventData(id) {
    return sportsBookClient.get(`event/${ id }?locale=${ lang.value }`);
  }

  searchByName(str) {
    return sportsBookClient.post(`search`, { query: str });//?locale=${ lang() }
  }

  selectedEventsData(filter = {}) {
    return sportsBookClient.post(`events?locale=${ lang.value }`, filter);
  }
  eventsInSport(filter) {
    return sportsBookClient.post(`experimental/events?locale=${ lang.value }`, filter);
  }
}
