import shared from '@/shared';

const {Cashdesk} = shared.api;

const state = {
  client_data: null,
  deposit_data: null,
  correlation_id: null,
  withdraval_info: null
};

const getters = {
  CLIENT_DATA(state) {
    return state.client_data;
  },
  DEPOSIT_DATA(state) {
    return state.deposit_data;
  },
  WITHDRAWAL_INFO(state) {
    return state.withdraval_info;
  },
  CORRELATION_ID(state) {
    return state.correlation_id;
  },
};

const mutations = {
  CLIENT_DATA(state, data) {
    state.client_data = data;
  },
  DEPOSIT_DATA(state, data) {
    state.deposit_data = data;
  },
  CORRELATION_ID(state, id) {
    state.correlation_id = id;
  },
  WITHDRAWAL_INFO(state, data) {
    state.withdraval_info = data;
  }
};

const actions = {
  async CREATE_CLIENT_DEPOSIT(context, request) {
    const resp = await Cashdesk.clientDeposit(request);

    context.commit('DEPOSIT_DATA', resp);
  },
  async FIND_CLIENT_BY_ID(context, id) {
    const {data} = await Cashdesk.clientInfo(+id);
    context.commit('CLIENT_DATA', data);
  },
  async GET_WITHDRAWAL_INFO_BY_ID(context, id) {
    context.commit('CORRELATION_ID', id);
    const {data} = await Cashdesk.withdrawInfo(+id);

    context.commit('WITHDRAWAL_INFO', {
      ...data,
      amount: (data.amount / 100).toFixed(2)
    })
  },
  async WITHDRAWAL_COMPLETE(context) {
    const id = context.getters['CORRELATION_ID'];
    const {data} = await Cashdesk.withdrawComplete(id);
    
    return data;
  },
  async PAYOUT_IN_CASH(context, data) {
    return await Cashdesk.payout(data);
  },
  async PAYOUT_TO_CLIENT_ACCOUNT(context, data) {
    return await Cashdesk.payoutDep(data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
