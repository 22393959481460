const state = {
  print_line_tournament_arr: null,
};

const getters = {
  PRINT_LINE_TOURNAMENT_ARR(state) {
    return state.print_line_tournament_arr;
  }
};

const mutations = {
  CHANGE_PRINT_LINE_TOURNAMENT_ARR(state, arr) {
    state.print_line_tournament_arr = arr;
  }
};

const actions = {

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
