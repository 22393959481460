const state = {
  error_data: null,
  show_err_modal: false,
  info_message: null,
  alerts_data: null
};

const getters = {
  ERROR_DATA(state) {
    return state.error_data;
  },
  INFO_MESSAGE(state) {
    return state.info_message;
  },
  ALERTS_DATA(state) {
    return state.alerts_data;
  }
};

const mutations = {
  MESSAGE_INFO_MODAL(state, message) {
    state.info_message = message;
  },
  ADD_ERROR_DATA(state, data) {
    state.error_data = data
    state.show_err_modal = true;
  },
  CLEAR_ERROR_MODAL(state) {
    state.error_data = null
  },
  ADD_ALERTS_DATA(state, data) {
    state.alerts_data = data;
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};