const PATHS = {
  LOGIN_PAGE: '/',
  CASHDESK_ID: '/cashdesks/:id',
  TERMINAL: '/terminal/:id',
  NOT_FOUND: '/404',
  PRINT_LINE: '/print-line/:id',
  //PLACE_BET_TERMINAL: '/place-bet-terminal/:id'
}

export default PATHS;
