import { createStore } from 'vuex';

import {
  cashierStore,
  balanceState,
  cashdeskDataStore,
  cashdeskConfigStore,
  notificationsStore,
  oddsBasketStore,
  couponStore,
  printLineStore,
  sportsbookConfigStore,
  eventState,
  sportsStore,
  categoriesStore,
  tournamentsStore,
  clientState,
  eventsState,
  bet
} from '@/entities';

//import bets from './modules/bets.js';

const store = createStore({
  modules: {
    bet,
    cashierStore,
    balanceState,
    clientState,
    cashdeskDataStore,
    cashdeskConfigStore,
    notificationsStore,
    oddsBasketStore,
    couponStore,
    printLineStore,
    sportsbookConfigStore,
    eventState,
    sportsStore,
    categoriesStore,
    tournamentsStore,
    eventsState,

   // bets,
  },
  actions: {},
  mutations: {
    SET_LOADER(state, bool) {
      state.loader_is_shown = bool;
    },
    SET_LOADER_DISABLE(state, bool) {
      state.loader_is_disabled = bool;
    },
    SET_CDN(state, cdn) {
      state.cdn_url = cdn;
    },
    SET_APP_TYPE(state, type) {
      state.app_type = type;
    },
    SET_PROJECT_ID(state, id) {
      state.projectId = id;
    }
  },
  state: {
    projectId: null,
    app_type: 'default',
    loader_is_shown: false,
    loader_is_disabled: false,
    cdn_url: 'https://media.pl-test.cdn-platform.xyz/cms'
  },
  getters: {
    PROJECT_ID(state) {
      return state.projectId;
    },
    LOADER_IS_SHOWN(state) {
      return state.loader_is_shown;
    },
    LOADER_IS_DISABLED(state) {
      return state.loader_is_disabled;
    },
    CDN_URL(state) {
      return state.cdn_url;
    },
    APP_TYPE(state) {
      return state.app_type;
    }
  }
})

export { store }
