<template>
  <div
      class="base-checkbox-container"
      :class="{'base-checkbox-no-active-container' : !active, 'base-checkbox-active-container' : active}"
      :style="dis ? {'background-color': '#c4c7d4'} : {}"
      @click="switchToggle"
  >
    <div
        class="base-checkbox-circle"
        :class="{'base-checkbox-circle-no-active' : !active, 'base-checkbox-circle-active' : active}"
        :style="dis ? {'background-color': '#dbe0ea'} : {}"
    >
    </div>
  </div>
</template>

<script>
import {
  ref, onMounted
} from 'vue';

export default {
  props: ['disabled', 'state'],
  emit: ['switch'],
  setup(props, { emit }) {
    const active = ref(false);

    onMounted(() => {
      if (props.state) active.value = props.state
    })

    const switchToggle = () => {
      if (!props.disabled) {
        active.value = !active.value;
        emit('switch', active.value);
      }
    }

    return {
      active,
      dis: props.disabled,
      switchToggle
    }
  }
}
</script>
