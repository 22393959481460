import { createWebHistory, createRouter } from 'vue-router';
import PATHS from './PATHS';

const routes = [
  {
    path: PATHS.LOGIN_PAGE,
    component: () => import('@/pages')
      .then(module => module.default.login)
  },
  {
    path: PATHS.CASHDESK_ID,
    component: () => import('@/pages')
      .then(module => module.default.cashdesk)
  },
  {
    path: PATHS.TERMINAL,
    name: 'sunmi-terminal',
    component: () => import('@/pages')
      .then(module => module.default.cashdeskTerminal)
  },
  {
    path: PATHS.NOT_FOUND,
    component: () => import('@/pages')
      .then(module => module.default.pageNotFound)
  },
  {
    path: PATHS.PRINT_LINE,
    component: () => import('@/pages')
      .then(module => module.default.printLine)
  },
  {
    path: "/:catchAll(.*)",
    redirect: PATHS.NOT_FOUND
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
