<script setup>
  import { defineProps, defineEmits, computed, ref, onMounted, watch } from 'vue';

  const isAbled = ref(false);

  const emit = defineEmits(['search']);
  const props = defineProps({
    config : Object({
      disabled: Boolean,
      labelText: String,
      validation: Function,
      style: String,
      model: Object,
      autofocus: {
        type: Boolean,
        require: false
      }
    })
  });

  const baseInputConfig = computed(() => {
    const {labelText, disabled, validation, model, autofocus} = props.config;
    return {
      width: '100%',
      style: {
        'border-radius': '4px 0 0 4px'
      },
      type: 'text',
      labelText,
      disabled,
      validation,
      model,
      autofocus
    }
  });


  watch(() => baseInputConfig?.value?.model?.value, () => {
    isAbled.value = !!baseInputConfig?.value?.model?.value;
  })

  const baseBtnConfig = computed(() => {
    const {disabled, validation} = props.config;
    
    return {
      icon: '#search-icon',
      style: {
        width: '25%',
        'max-width': '60px',
        'border-radius': '0 4px 4px 0',
        padding: '10px'
      },
      variant: 'primary',
      disabled: !isAbled.value || disabled || (validation && !validation(baseInputConfig?.value?.model?.value)),
      func() {
        emit('search', baseInputConfig.value.model.value)
      }
    }
  });

  onMounted(() => {
    if (props.config?.model.value) {
      isAbled.value = true;
    }
  })
</script>

<template>
  <div :style="props.config?.style" class="ui-search-container">
    <ui-input @emitInputData="baseBtnConfig.func" :baseInputConfig="baseInputConfig" />
    <ui-button class="btn-brand" :baseBtnConfig="baseBtnConfig"/>
  </div>
</template>
