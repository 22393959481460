const compareOdds = (marketTypeItem, storeItem) => {
  if (marketTypeItem.odds < storeItem.odds) {
    storeItem.chengeOdds = 'UP'
  } else if (marketTypeItem.odds > storeItem.odds) {
    storeItem.chengeOdds = 'DOWN'
  }
}

export const oddsHandler = (marketType, { formattedMarketTypes }) => {
  const marketFindOnStore = formattedMarketTypes
    .find((i) => i.title === marketType.title);

  if (marketFindOnStore) {
    marketType.rows.forEach((mtRowItem) => {
      mtRowItem.forEach((item) => {
        marketFindOnStore.rows.forEach((storeRowsItem) => {
          const findId = storeRowsItem.find(i => i.id === item.id);
          findId ? compareOdds(findId, item) : null;
        })
      })
    })
  }
}

