<template>
  <div
      class="big-button-container"
      :class="config.variant || ''"
      :style="config.style"
      @click="config.func()"
  >
    <div class="big-button-icon-container">
      <img :src="config.img" alt="icon" />
    </div>
    <div class="btn-text-container cut-text">
      {{ config.text }}
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  props: ['bigButtonConfig'],
  setup(props) {
    const config = ref(props.bigButtonConfig);
    return {
      config
    }
  }
}
</script>
