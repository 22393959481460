
const state = {
  coupon_data: null,
};

const getters = {
  COUPON_DATA(state) {
    return state.coupon_data;
  },
};

const mutations = {
  ADD_COUPON_DATA(state, data) {
    state.coupon_data = data;
  },
  CLEAR_COUPON_DATA(state) {
    state.coupon_data = null;
  }
};

const actions = {

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
