export function marketTypeFormaterCricketSeparator(data, haveHeader) {
  if (haveHeader) data.header = [false, 'Over', 'Under']
  const rows = data.rows.reduce((row, item) => {
    const specifiers = item[0].specifiers;

    const name = specifiers.slice(0, specifiers.indexOf('/'));
    const cef = specifiers.slice(specifiers.indexOf('/') + 1, specifiers.length);

    const over = item.find(a => a.type === 'Over');
    const under = item.find(a => a.type === 'Under');

    over.name = under.name = cef;

    const itemArr = [
      {
        isFake: true,
        name: name
      },
      over,
      under
    ]
    row.push(itemArr);
    return row;
  }, [])
  data.rows = rows;
}
/**
 *
 * @param {obj} data
 * @param {num} sportId
 * @param {boll} point
 */
export function marketTypeRaceTo(data, sportId, point) {
  const arr = data.rows.reduce((a, i) => {
    a.push(...i);
    return a;
  }, [])

  const specifiersArr = arr.reduce((s, i) => {
    s.push(i.specifiers);
    return s
  }, []);

  const headerArr = arr.reduce((s, i) => {
    if (i.name) {
      const nameNum = String(parseInt(i.name.match(/\d+/)));
      const delNum = i.name.indexOf(String(nameNum));
      const p = i.name.slice(0, delNum - 1);

      s.push(p);
    }

    return s
  }, []);
  headerArr.unshift(false);

  data.header = Array.from(new Set(headerArr));

  const formatSpecifiersArr = Array.from(new Set(specifiersArr));

  let rowsArr = formatSpecifiersArr.reduce((a, i) => {
    const rowArr = arr.filter(a => a.specifiers == i);
    rowArr.forEach(cell => {
      if (cell.name) {
        const nameNum = String(parseInt(cell.name.match(/\d+/)));
        const delNum = cell.name.indexOf(String(nameNum));
        const p = cell.name.slice(0, delNum - 1);

        cell.name = p
      }
    });

    try {
      rowArr.sort((a, b) => {
        return data.header.indexOf(a.name) - data.header.indexOf(b.name);
      });
    } catch(err) {
      // console.error(err)
    }

    rowArr.forEach(n => {
      n.name = '';
    })
    if (point) {
      const nameArr = i.split('/');
      let createStr;

      if (sportId !== 7) {
        createStr = nameArr.reduce((str, item, index) => {
          if (index === 0)
            str = item + 'Set ';
          else if (index === 1 )
            str = str + item + 'Game ';
          else if (index === 2 )
            str = str + item + 'Pt ';

          return str;
        }, '')
      } else {
        createStr = nameArr.reduce((str, item, index) => {
          if (index === 0)
            str = item + 'Game ';
          else if (index === 1 )
            str = str + item + 'Pt ';

          return str;
        }, '')
      }
      i = createStr;
    }
    rowArr.unshift({
      name: i,
      isFake: true,
    })
    a.push(rowArr);
    return a;
  }, [])

  data.rows = rowsArr;
}

export function pointWinner(marketType) {
  let marketArToObj =  marketType.rows.map(arr => {
    const obj = Object.assign({}, arr)
    obj.sortHealp = obj[1].specifiers.split('/')
    return obj;
  })

  marketType.rows = marketArToObj.sort((a, b) => {
    return a.sortHealp[2] - b.sortHealp[2]
  })
    .sort((a, b) => {
      return a.sortHealp[1] - b.sortHealp[1]
    })
    .sort((a, b) => {
      return a.sortHealp[0] - b.sortHealp[0]
    })

  marketType.rows = Object.values(marketType.rows).map(i => {
    delete i.sortHealp
    return Array.isArray(i) ? i : Object.values(i);
  });
}
