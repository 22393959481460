import axios from 'axios';
import config from '@/shared/api/config/config.js';

const publicRetail = axios.create(config.init('api/public-retail/retail-service'));

publicRetail.interceptors.request.use(config.request.success, config.request.failure);

publicRetail.interceptors.response.use(config.response.success, config.response.failure);

export default class RetailPublic {
  getSportForm(data = {}) {
    return publicRetail.post(`market-type-setting`, data)
  }
}
