import amountInDollars from './amount-in-dollars';
// import ticketPrinter from './ticket-printer';
import drawTicket from './ticket-printer';
import { localstorageSet } from './localstorage-set';
import { localstorageDelete } from './localstorage-delete';
import { getErrorCode } from './get-error-code';
import { clearAllSetTimeout } from './clear-all-set-timeout';
import { sportsbookFilterObject } from './sportsbook-filter-object';
import { recursiveCallBack } from './recursive-call-back';

export default {
    amountInDollars,
    // ticketPrinter,
    drawTicket,
    localstorageSet,
    localstorageDelete,
    getErrorCode,
    clearAllSetTimeout,
    sportsbookFilterObject,
    recursiveCallBack
}
