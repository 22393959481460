<template>
  <div class="loader">
    <h3>
      Loading...
    </h3>
  </div>

</template>

<style>
.loader {
  position: absolute;
  z-index: 5;
  max-width: 100px;
  max-height: 100px;
  width: 130px;
  height: 130px;
  left: calc(50% - 75px);
  top: calc(50% - 30px);
  color: #fff;
}
.loader > h3 {
  width: 150px;
  padding: 1rem;
  background: #2d3146;
  border-radius: 5px;

}
</style>
