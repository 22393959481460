<template>
  <template v-for="(item, index) in config">
    <div
      class="button-factory-item"
      :key="index"
      v-if="item.show != null ? item.show : true"
    >
      <ui-button :baseBtnConfig="item" />
    </div>
  </template>
</template>

<script>
import { ref } from 'vue';

export default {
  props: ['buttonFactoryConfig'],
  setup(props) {
    const config = ref(props.buttonFactoryConfig)
    return {
      config
    }
  }
}
</script>

<style scoped>
.button-factory-item {
  margin-right: 5px;
  width: 100%;
}
.button-factory-item:last-child {
  margin-right: unset;
}
</style>
