import shared from '@/shared';

const {Cashdesk} = shared.api;
const { localstorageSet } = shared.model;

const req = {
  limit: 100,
  offset: 0
}

const state = {
  cashdesk_data: null,
  transactions_history: []
};

const getters = {
  GET_CASHDESK_DATA(state) {
    return state.cashdesk_data
  },
  TRANSACTIONS_HISTORY(state) {
    return state.transactions_history
  }
};

const mutations = {
  CHANGE_CASHDESK_DATA(state, data) {
    localstorageSet({
      cashdesk_data: JSON.stringify(data)
    })
    state.cashdesk_data = data;
  },
  TRANSACTIONS_HISTORY(state, data) {
    state.transactions_history.push(...data);
  },
  CLEAR_TRANSACTION_STATE() {
    req.offset = 0;
    state.transactions_history = [];
  }
};

const actions = {
  async LOAD_CASHDESK_TRANSACTIONS_HISTORY(context) {
    const resp = await Cashdesk.cashierHistory(req);
    req.offset = resp.data.paging.offset;
    context.commit('TRANSACTIONS_HISTORY', resp.data.histories);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
