import shared from '@/shared';
const { SportData, Cashdesk, Booking } = shared.api;
const { localstorageDelete } = shared.model;
import { errorCodeObject } from '@/entities';

import { formatAddedItem } from './formatAddedItem.js';
import { setLocalBasket } from './setLocalBasket.js';
import { addMoreEventOdds } from './addMoreEventOdds.js';
import { deleteItemInBasket } from './deleteItemInBasket.js';
import { oddsHnadlerUpdateBaseStoreItem } from './oddsHnadlerUpdateBaseStoreItem.js';
import { oddsHandlerUpdateMarkets } from './oddsHandlerUpdateMarkets';
import { writeBookingData } from './writeBookingData';

const state = {
  odds_basket: [],
  change_coefficient_arr: [],
  booking: false,
  booking_initial: [],
  bet_variant: 'MULTIPLE',
  change_coefficient: 'YES',
  bet_amount: '',
  max_bet: '',
  possible_win: {
    totalOdds: 0,
    possibleWin: 0
  },
  outcome_length: 0
};

const getters = {
  ODDS_BASKET(state) {
    return state.odds_basket;
  },
  CHANGE_COEFFICIENT_ARR(state) {
    return state.change_coefficient_arr;
  },
  BOOKING(state) {
    return state.booking;
  },
  GET_OUTCOME_LENGTH(state) {
    return state.outcome_length = state.odds_basket.reduce((count, item) => {
      return count = count + item.market.length;
    }, 0)
  },
  GET_BET_VARIANT(state) {
    return state.bet_variant;
  },
  GET_CHANGE_COEFFICIENT(state) {
    return state.change_coefficient;
  },
  GET_BET_AMOUNT(state) {
    return state.bet_amount;
  },
  GET_MAX_BET(state) {
    return state.max_bet;
  },
  GET_HAS_INACTIVE_ODDS(state) {
    let bool = false
    state.odds_basket.forEach(eventItem => {
      eventItem.market.forEach(marketItem => {
        if (marketItem.outcomes.status != 'ACTIVE') {
          bool = true;
        }
      })
    })
    return bool;
  },
  GET_POSSIBLE_WIN(state) {
    return state.possible_win
  },
  GET_BET_TYPE(state) {
    let betType = state.outcome_length < 2 ? 'SINGLE' : 'MULTIPLE';

    if (state.outcome_length >= 3 && Number.isInteger(state.bet_variant)) {
      betType = 'SYSTEM';
    }
    return betType
  }
};

const mutations = {
  INIT_BOOKING(state, preOrderData){
    state.booking_initial = preOrderData;
  },
  BOOKING_CHANGE(state, data) {
    state.booking = data;
  },
  CLEAR_ODDS_CHANGE_ARRAY(state) {
    state.change_coefficient_arr = [];
  },
  ODDS_HANDLER(state, eventsData) {
    const changeCefs = []

    state.odds_basket.forEach(storeItem => {
      const eventData = eventsData[storeItem.tournament_id]?.events[storeItem.event_id];

      oddsHnadlerUpdateBaseStoreItem(storeItem, eventData);

      oddsHandlerUpdateMarkets(
        storeItem.market,
        eventData,
        state.booking_initial,
        changeCefs,
        state.change_coefficient_arr
      )
    })
  },

  ODDS_BASKET_ADD_ITEM(state, data) {
    const findEventId = state.odds_basket.find((id) => {
      const dataEventID = data.event.id ? data.event.id : +data.event.eventId
      return id.event_id === dataEventID;
    });

    findEventId ? addMoreEventOdds(findEventId, data) : state.odds_basket.push(formatAddedItem(data))

    setLocalBasket(state.odds_basket);
  },

  ODDS_BASKET_DELETE_ITEM(state, data) {
    deleteItemInBasket(data, state.odds_basket);
    setLocalBasket(state.odds_basket);
  },

  CLEAR_ODDS_BASKET(state) {
    if (localStorage['odds_basket']) { localstorageDelete(['odds_basket']) }
    state.odds_basket = [];
  },

  LOCAL_ODDS_BASKET(state) {
    state.odds_basket = JSON.parse(localStorage['odds_basket']);
  },
  SET_BET_VARIANT(state, betVariant) {
    state.bet_variant = betVariant;
  },
  SET_CHANGE_COEFFICIENT(state, variant) {
    state.change_coefficient = variant;
  },
  SET_BET_AMOUNT(state, value) {
    state.bet_amount = value;
  },
  SET_MAX_BET(state, value) {
    state.max_bet = value;
  },
  SET_POSSIBLE_WIN(state, obj) {
    state.possible_win = { ...obj }
  }
};

const actions = {
  SELECTED_ODDS(context, filter) {
    SportData.selectedEventsData({filter: { eventIds: filter } })
      .then((resp) => {
        context.commit('ODDS_HANDLER', resp.data.data);
      })
  },

  MAX_BET(context, filter) {
    Cashdesk.getMaxBet(filter)
      .then((resp) => {
        context.commit('SET_MAX_BET', resp.data.maxBetLimit / 100)
      })
  },

  async SEND_BET(context, data) {
    try {
      const resp = await Cashdesk.sendBet(data);
      localStorage.setItem('placeBetRequestId', resp.data.placeBetRequestId);
      return resp.data.placeBetRequestId
    } catch (err) {
      errorCodeObject(err);
    }
  },

  async BET_RESPONSE() {
    try {
      const resp = await Cashdesk.getBetResponse(localStorage['placeBetRequestId']);
      return resp.data;
    } catch (err) {
      errorCodeObject(err);
    }
  },

  async FIND_BOOKING_BY_ID(context, id) {
    try {
      const { data } = await Booking.getPreOrder(id);
      writeBookingData(data.bets, context);
    } catch(err) {
      errorCodeObject(err);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
