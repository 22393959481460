<script setup>
import { ref, defineProps, defineEmits, onMounted } from 'vue';

const props = defineProps({ baseInputConfig : Object({
    type: null,
    model: String,
    disabled: Boolean,
    labelText: String,
    validation: Function,
    style: Object,
    focus: Boolean,
    autofocus: {
      type: Boolean,
      require: false
    },
    button: {
      func: Function,
      btnIcon: String
    }
  })
});
const emit = defineEmits(['emitInputData'])
const config = ref(props.baseInputConfig);

const emitData = () => {
  if (!config.value.validation || (config.value.validation && config.value.validation(config.value.model))) {
    emit('emitInputData');
  }
};

const randomInputId = `input-${ Math.floor(Math.random() * 1000) }`

const focusInput = () => {
  const getInputId = config.value.id ? config.value.id : randomInputId;

  if (config.value.autofocus) {
    const input = document.getElementById(getInputId);
    input.focus();
  }
}

onMounted(() => {
  focusInput();
})
</script>

<template>
  <div
    class="input-field"
    :style="{ width: config?.width }"
  >
    <input
      :id="config?.id ? config?.id : randomInputId"
      class="base-input-tag"
      :type="config?.type"
      v-model="config.model"
      :disabled="config.disabled"
      :class="{
        invalid: config.hasOwnProperty('validation') && config.validation && !config.validation(config.model),
        active: !!config.model
      }"
      :style="config.style || {}"
      v-on:keyup.enter="emitData"
    >

    <label class="input-label">{{ config.labelText }}</label>

    <template v-if="config.button">
      <button @click="config.button.func()" class="input-button">
        <svg fill="#fff" width="24" height="24">
          <use :href="config.button.btnIcon"></use>
        </svg>
      </button>
    </template>

    <div v-if="config.disabled" class="input-disabled">
      <svg class="base-svg-size rotate-item-plus-90">
        <use xlink:href="../../../shared/assets/dash-circle.svg#dash-circle"></use>
      </svg>
    </div>
  </div>
</template>
