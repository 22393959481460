import shared from '@/shared';
import { errorCodeObject } from '@/entities/notifications/model/errorCodeObject.js';


const { Login } = shared.api;

export const cashDeskLogin = () => Login
  .cashDeskLogin()
  .then((json) => json.data)
  .catch((err) => errorCodeObject(err))
