import shared from '@/shared';
const { SportData } = shared.api;

const state = {
  sportsbook_config: null,
};

const getters = {
  SPORTSBOOK_CONFIG(state) {
    return state.sportsbook_config;
  },
};

const mutations = {
  SET_SPORTSBOOK_CONFIG(state, data) {
    state.sportsbook_config = data;
  },
};

const actions = {
  LOAD_SPORTSBOOK_CONFIG(context) {
    SportData.getConfig().then(({data}) => {
      context.commit('SET_SPORTSBOOK_CONFIG', data);
    })
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
