import shared from '@/shared';

const { Cashdesk, PublicApi } = shared.api;

const state = {
  cupon_data: null,
  can_be_printed: false
};

const getters = {
  CUPON_DATA(state) {
    return state.cupon_data;
  },
  CAN_BE_PRINTED(state) {
    return state.can_be_printed;
  }
};

const mutations = {
  CUPON_DATA(state, data) {
    state.cupon_data = data;
  },
  CAN_BE_PRINTED(state, bool) {
    state.can_be_printed = bool;
  }
};

const actions = {
  async LOAD_BET_DATA(context, id) {
    const response = await Cashdesk.getBetData(id);
    return response;
  },
  async FIND_CUPON(context, id) {
    const response = await Cashdesk.findCoupon(id);
    const statusResponse = await PublicApi.getBetData(id);

    response.data.details.outcomes.forEach((responseItem) => {
      const findNewStatus = statusResponse.data.outcomes
        .find((i) => i.outcomeId === responseItem.outcomeId);

      responseItem.outcomeInfo.outcomeStatus = findNewStatus.outcomeInfo.outcomeStatus;
    })

    context.commit('CUPON_DATA', response.data);
  },
  async CHECK_PRINT_COPY_STATUS(context, id) {
    const resp = await Cashdesk.canBePrinted(id)
    context.commit('CAN_BE_PRINTED', resp.data.canBePrinted);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
