import { createApp } from 'vue';
import App from './App.vue';

const app = createApp(App);

import { store } from './store/store.js';
import initDevice from '@/app/initDevice.js';
import router  from './route/route.js';
import i18n from '@/app/i18n.js';

import shared from '@/shared';
const { ui, model } = shared;

if (localStorage['auth']) {
  const cashDeskData = JSON.parse(localStorage['cashdesk_data'])
  store.commit('cashierStore/AUTH_DATA', JSON.parse(localStorage['auth']));
  store.commit('cashdeskDataStore/CHANGE_CASHDESK_DATA', cashDeskData)
  store.dispatch('cashierStore/TOKEN_VALIDATE');
  store.dispatch('cashdeskConfigStore/GET_LOGO');
}
const lang = localStorage['lang'] || 'ENG';
store.commit('cashdeskConfigStore/SET_NEW_LANG', lang);

if (localStorage['dashboard_theme']) {
  let theme = JSON.parse(localStorage['dashboard_theme']);
  if (store.getters['APP_TYPE'] === 'terminal') {
    theme = true;
  }
  store.commit('cashdeskConfigStore/SET_THEME', theme);
} else {
  store.commit('cashdeskConfigStore/SET_THEME', true);
}

initDevice();

import '@/app/scss/styles.scss';

if (window.NODE_ENV === 'development') {
  store.commit('SET_CDN', 'https://media.pl-test.cdn-platform.xyz/cms');
} else {
  let cdnLink = '';
  switch (window.NODE_ENV) {
    case 'test':
      cdnLink = 'https://media.pl-test.cdn-platform.xyz/cms';
      break;
    case 'stage1':
      cdnLink = 'https://media.pl-stage1.cdn-platform.xyz/cms';
      break;
    case 'production':
      cdnLink = 'https://media.pl-01.cdn-platform.xyz/cms';
      break;
  }
  store.commit('SET_CDN', cdnLink);
}

if (localStorage['switchMonitorView']) {
  store.commit('cashdeskConfigStore/CHANGE_SWITCH_MONITOR_VIEW', JSON.parse(localStorage['switchMonitorView']))
}

app.use(i18n);
app.use(store);
app.use(router);

app.provide('sharedModel', model);

for (let key in ui) {
  const componentGlobalName = key
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .toLowerCase();

  app.component(componentGlobalName, ui[key]);
}

app.mount('#app');






