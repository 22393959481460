export const addMoreEventOdds = (eventInBasket, data) => {
  const betGroups = data.outcome.bet_groups ? data.outcome.bet_groups : data.event.betMarketGroups;
  betGroups.forEach((addingGroup) => {
    eventInBasket.market = eventInBasket.market.filter((market) => {
      return !market.bet_groups.includes(addingGroup)
    })
  })

  try {
    return eventInBasket.market.push({
      bet_groups: data.outcome.bet_groups || betGroups,
      market_id: data.outcome.market_id || +data.event.marketId,
      marketId: data.outcome.marketId || +data.event.marketTypeId,
      marketName: data.outcome.marketName || data.event.marketName,
      outcomes: data.outcome
    })
  } catch(err) {
    console.log('err', err);
  }
}
