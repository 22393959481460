export const dateFormats = {
  'ENG': {
    headerDate : {
      month: 'short', day: 'numeric',
      hour: 'numeric', minute: 'numeric', hour12: true
    },
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    shortDate: {
      month: 'short', day: 'numeric'
    },
    couponDate: {
      year: 'numeric', month: 'short', day: 'numeric',
      hour: 'numeric', minute: 'numeric', hour12: true
    },
    time: {
      hour: 'numeric', minute: 'numeric', hour12: true
    },
    time24: {
      hour: 'numeric', minute: 'numeric', hour12: false
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric'
    }
  },
  'UKR': {
    headerDate : {
      month: 'short', day: 'numeric',
      hour: 'numeric', minute: 'numeric', hour12: false
    },
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    shortDate: {
      month: 'short', day: 'numeric'
    },
    couponDate: {
      year: 'numeric', month: 'short', day: 'numeric',
      hour: 'numeric', minute: 'numeric', hour12: false
    },
    time: {
      hour: 'numeric', minute: 'numeric', hour12: false
    },
    time24: {
      hour: 'numeric', minute: 'numeric', hour12: false
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
    }
  },
  'RUS': {
    headerDate : {
      month: 'short', day: 'numeric',
      hour: 'numeric', minute: 'numeric', hour12: true
    },
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    shortDate: {
      month: 'short', day: 'numeric'
    },
    couponDate: {
      year: 'numeric', month: 'short', day: 'numeric',
      hour: 'numeric', minute: 'numeric', hour12: false
    },
    time: {
      hour: 'numeric', minute: 'numeric', hour12: false
    },
    time24: {
      hour: 'numeric', minute: 'numeric', hour12: false
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
    }
  },
  'TUR': {
    headerDate : {
      month: 'short', day: 'numeric',
      hour: 'numeric', minute: 'numeric', hour12: true
    },
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
    }
  }
}
