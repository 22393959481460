<template>
  <div class="modal-container">
    <div class="modal-popup-content-container">
      <!-- <div class="modal-popup-header">
        {{ $t('successful') }}
      </div> -->
      <div class="modal-popup-body">
        <slot name="modal-popup-body"></slot>
      </div>
      <div class="popup-btn-container">
        <ui-button :baseBtnConfig="baseBtnConfig" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from 'vue-i18n';

export default {
  props: [],
  emits: ['ok'],
  setup(props, { emit }) {
    const { t } = useI18n();

    return {
      baseBtnConfig: {
        text: computed(() => t('ok')),
        variant: 'btn-brand',
        //style: {width: '100px'},
        func: () => {
          emit('ok')
        }
      }
    }
  }
}
</script>
