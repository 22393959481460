import axios from 'axios';
import { store } from "@/app/store/store";

const localInstance = axios.create({
    baseURL: 'http://localhost:8063',
    timeout: 25000,
    headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    },
});

localInstance.interceptors.request.use((config) => {
    const { sessionToken, sessionProject } = store.getters["cashierStore/AUTH"];
    config.headers.Authorization = `bearer ${ sessionToken }`;
    config.headers['x-project-id'] = sessionProject;

    return config;
});

export default class Local {
    getLogo(url) {
        return localInstance.post('/logo', { url: url });
    }

    localPrinterPrintBet(obj = {}) {
        return localInstance.post(`/ticket-lines:print`, obj);
    }

    localPrinterPrint(obj = {}) {
        return localInstance.post(`/lines:print`, obj);
    }
}
