import i18n from "@/app/i18n";
const {t } = i18n.global;

const logout = (context) => {
  context.dispatch('LOGOUT_CASHIER');
}

const alertPopup = (context, minutesLeft) => {
  context.commit('notificationsStore/MESSAGE_INFO_MODAL',
    t('shift_attention', { minutes: Math.round(minutesLeft) }),
    { root: true }
  );
}

export const timeToLogout = (minutesLeft, checkShiftInterval, context, minute) => {
  const left = minutesLeft > 0 ? minutesLeft * minute : 0;

  if (minutesLeft <= 10) {
    clearInterval(checkShiftInterval);

    if (minutesLeft > 0) alertPopup(context, minutesLeft)

    setTimeout(() => logout(context), left);
  }
}
