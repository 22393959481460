export const oddsHandlerUpdateMarkets = (storeItemMarket, eventData, booking, changeCefs, stateChangeCefs) => {
  storeItemMarket.forEach(marketItem => {
    const outcomeData = eventData?.marketTypes[marketItem.marketId]?.markets[marketItem.market_id]?.outcomes[marketItem.outcomes.id];
    const initialOutcome = booking.find(outcome => outcome.outcomeId === marketItem.outcomes.id);

    if (outcomeData) {
      if (initialOutcome){
        marketItem.outcomes.initialOdds = initialOutcome.odds;
      }
      marketItem.outcomes.status = outcomeData.status;
      marketItem.outcomes.oddsChange = '';
      if (marketItem.outcomes.odds > outcomeData.odds) {
        marketItem.outcomes.oddsChange = 'DOWN';
        changeCefs.push('DOWN');
      }
      else if (marketItem.outcomes.odds < outcomeData.odds) {
        marketItem.outcomes.oddsChange = 'UP';
        changeCefs.push('UP');
      }
      stateChangeCefs.push(...changeCefs);
      marketItem.outcomes.odds = outcomeData.odds;
    }
  })
}
