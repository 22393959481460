export const writeBookingData = (bookingData, context) => {
  bookingData.forEach(item => {
    const betType = item.betSize ? item.winCombinationSize : item.betType;

    context.commit('BOOKING_CHANGE', betType)

    item.outcomes.forEach(outcomeData => {
      const bookingData = {
        outcome: {
          id: outcomeData.outcomeId,
          odds: outcomeData.odds,
          name: outcomeData.outcomeInfo.localizedOutcomeName || outcomeData.outcomeInfo.outcomeName
        },
        event: outcomeData.outcomeInfo
      }

      context.commit('ODDS_BASKET_ADD_ITEM',bookingData )
    })
    context.commit('INIT_BOOKING', item.outcomes)
  })
}
