import { store } from '@/app/store/store';

export default function() {
  /**
   * @type {string} 'terminal' || 'default'
   */
  const appType = () => {
    let type = window?.Android?.isTerminal() ? window?.Android?.isTerminal() : 'default'

    if (window.NODE_ENV !== 'production') {
      localStorage['test_terminal'] ? type = 'terminal' : null;
    }

    return type;
  };

  store.commit('SET_APP_TYPE', appType())
}


