import router from '@/app/route/route.js';

import shared from '@/shared';
const { localstorageDelete, getErrorCode } = shared.model;
import { errorCodeObject } from '@/entities/notifications/model/errorCodeObject.js';

const { Login } = shared.api;
import i18n from '@/app/i18n.js';
const {t} = i18n.global;

export const loginUser = (data, context) => Login
  .goLogin(data)
  .then((json) => json.data)
  .catch(err => {
    if (getErrorCode(err) === '401') {
      context.commit('notificationsStore/ADD_ERROR_DATA', {
        header: t('error'),
        body: t('wrong_login_or_password'),
      }, { root: true });
    } else {
      errorCodeObject(err);
    }
    context.commit('AUTH_DATA', {});
    localstorageDelete(['auth']);
    router.push('/');
  })


