export const canvasBlackLogo = (img, context) => {
  let canvasBlackLogo = document.getElementById("canvas-logo");
  canvasBlackLogo.innerHTML = img;

  try {
    let {width, height} = canvasBlackLogo.firstChild.getBBox();
    let blob = new Blob([canvasBlackLogo.innerHTML],{type:'image/svg+xml;charset=utf-8'});
    let blobURL = URL.createObjectURL(blob);

    let logo = new Image();
    logo.crossOrigin = 'cross-origin';
    logo.onload = () => {
      console.log('logo', logo)
      
      let canvas = document.createElement('canvas');
      canvas.width = width * 1.5;
      canvas.height = height * 1.5;
      let ctx = canvas.getContext('2d');
      ctx.drawImage(logo, 0, 0, canvas.width, canvas.height);
      let base64Image = canvas.toDataURL('image/png');

      context.commit('ADD_LOGO', {logo, base64: base64Image, width: canvas.width, height: canvas.height});
    }
    logo.src = blobURL;
  } catch(err) {
    console.log(err)
  }
}
