import { computed } from "vue";

export function amountInDollars(amountInCents) {
    const local = computed(() =>{
        let lang = 'en';
        if (localStorage['lang']) {
            const find_index = localStorage['lang'].indexOf('_');
            lang = find_index !== -1
                ? localStorage['lang'].substr(find_index + 1, localStorage['lang'].length)
                : localStorage['lang'];
        }
        return lang
    });
    const currency = computed(() => localStorage['currency'] ? localStorage['currency'] : '')
    try {
        return (amountInCents / 100).toLocaleString(local.value, {
            style: "currency",
            currency: currency.value,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
    }  catch (err) {
        console.log('err', err);
        return `${(amountInCents / 100).toFixed(2)} ${currency.value}`;
    }
}
