export const sportsbookFilterObject = (updateKeys) => {
  const baseObject = {
    fromDate: null,
    sportServices: ["LIVE", "PREMATCH"],
    timeHours: null,
    toDate: null
  }

  return updateKeys ? { ...baseObject, ...updateKeys } : baseObject;
}
