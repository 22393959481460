const formatParticipants = (participants) => {
  const createParticipantsObject = () => {
    return {
      home: participants.find((i) => i.qualifier === 'home'),
      away: participants.find((i) => i.qualifier === 'away'),
    }
  }
  return Array.isArray(participants)
    ? createParticipantsObject()
    : participants
}

export const formatAddedItem = (oddsData) => {
  const dataEvent = { ...oddsData.event };
  const outcome = { ...oddsData.outcome }

  const itemFormatObject = {
    participants: formatParticipants(dataEvent.participants),
    event_name: dataEvent.name || dataEvent.eventName,
    event_id: dataEvent.id || +dataEvent.eventId,
    event_startTime: dataEvent.startTime || dataEvent.eventStartTime,
    event_sportService: dataEvent.sportService,
    event_currentScore: `${dataEvent.info?.score?.homeScore}:${dataEvent?.info?.score?.awayScore}`,
    event_liveStatus: dataEvent?.info?.score?.liveStatus,
    event_matchClock: `${Math.round(dataEvent?.info?.score.matchClock/60)}:00`,
    sport_name: dataEvent.sportName || dataEvent.localizedSportName,
    category_name: dataEvent.categoryName || dataEvent.localizedCategoryName,
    tournament_name: dataEvent.tournamentName || dataEvent.localizedTournamentName,
    tournament_id: +dataEvent.tournamentId,
    market: [{
      bet_groups: outcome.bet_groups || outcome.betGroups || dataEvent.betMarketGroups,
      marketId: outcome.marketId || +dataEvent.marketTypeId,
      marketName: outcome.marketName || dataEvent.marketName,
      market_id: outcome.market_id || +dataEvent.marketId,
      outcomes: outcome
    }]
  }

  return itemFormatObject;
}
