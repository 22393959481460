<template>
  <div class="modal-container">
    <div class="modal-error-content-container">
      <div class="modal-error-header">
        {{ $t('error') }}
      </div>
      <div class="modal-error-body">
        <slot name="modal-error-body"></slot>
      </div>
      <div class="popup-btn-container">
        <ui-button-factory :buttonFactoryConfig="buttonFactoryConfig" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  props: ['code'],
  emits: ['close'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const buttonFactoryConfig = ref([
      {
        text: computed( () => t('close')),
        variant: 'btn-primary',
        disabled: false,
        func: () => {
          emit('close', props.code);
        }
      }
    ])

    if (props.code === 500) {
      buttonFactoryConfig.value.push({
        text: computed(() => t('reload')),
        variant: 'btn-brand',
        disabled: false,
        func: () => {
          nextTick(() => {
            location.reload();
          });
        }
      })
    }
    return {
      buttonFactoryConfig,
      t
    }
  }
}
</script>
