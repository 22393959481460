import axios from 'axios';
import config from '@/shared/api/config/config.js';

const retailSystemInstance = axios.create(config.init('bo-retail/ordering'));

retailSystemInstance.interceptors.request.use(config.request.success, config.request.failure);

retailSystemInstance.interceptors.response.use(config.response.success, config.response.failure);

export default class Ordering {
  cancelBet(obj){
    return retailSystemInstance.post(`/bet:cancel`, obj)
  }
}
