<script setup>
import { ref } from 'vue';

const showOptions = ref(false);
const navbarShowOptions = () => {
  showOptions.value = !showOptions.value;
}
</script>

<template>
  <div class="ui-navbar-container">
<!--    <button @click="navbarShowOptions" class="ui-navbar-button">-->
    <div @click="navbarShowOptions" class="ui-navbar-button">
      <svg>
        <use xlink:href="../../../shared/assets/settings.svg#settings"></use>
      </svg>
    </div>
<!--    </button>-->
    <div v-if="showOptions" class="ui-navbar-options">
      <slot name="ui-navbar-body"></slot>
    </div>
  </div>
</template>
