/* eslint-disable */
import { marketTypeParser } from "@atlas/display-layouts";
import {
  marketTypeFormaterCricketSeparator,
  marketTypeRaceTo,
  pointWinner
} from './noStandartMarketFormat';

import { oddsHandler } from './oddsHandler';

const isMarketTypeRaceTo = (marketType, sportId) => {
  return (marketType.type === 'race_to' || marketType.type === 'next_goal')
    && marketType.title !== 'Point Betting'
    && sportId !== 2;
}

export const formatEventData = (eventData, layoutData) => {
  if (!eventData) {
    return
  }
  const { marketTypes } = eventData;
  const sortedMarketTypes = Object.values(marketTypes)
    .sort((a, b) => a.orderPosition - b.orderPosition);

  const formattedMarketTypes = sortedMarketTypes.reduce((arr, item) => {
    let marketType;
    try {
      marketType = marketTypeParser({
        event: eventData,
        marketType: item,
        feeds: {},
      })

      if (marketType.type === 'cricket_separator_header') {
        marketTypeFormaterCricketSeparator(marketType, true);
      }

      if (marketType.type === 'cricket_separator') {
        marketTypeFormaterCricketSeparator(marketType, false);
      }

      if (isMarketTypeRaceTo(marketType, eventData.sportId)) {
        marketTypeRaceTo(marketType, eventData.sportId, null);
      }

      if (marketType.type === 'point_winner' || marketType.type === 'game_to_deuce') {
        marketTypeRaceTo(marketType, eventData.sportId, true);
        if (marketType.type == 'point_winner') {
          pointWinner(marketType);
        }
      }

      if (layoutData) oddsHandler(marketType, layoutData);

      marketType.betGroups = item.betGroups
      marketType.id = item.id

      arr.push(marketType);
    } catch(err) {

    }
    return arr;
  }, [])

  return {
    formattedMarketTypes,
    eventData
  }
}
