<template>
  <div
    class="drop-down-container"
    :style="config?.style ? config.style : {}"
    :id="dropDownId"
  >
    <button
      :disabled="config.disabled"
      @click="isFocus"
      @blur="isBlur"
      class="drop-down-select"
      :class="{ 'drop-down-disabled': config.disabled }"
    >
      <span>
        {{ selectedItem ? selectedItem.name : config.baseText }}
      </span>
      <span class="down-arrow" :class="{'open-options-dropdown-icon': show}"></span>

      <span
        v-show="show && config.options"
        class="drop-down-options-container"
        :id="optionsId"
      >
        <span
          v-for="item in config.options"
          :key="item.id"
          class="drop-down-option-item"
          @click="selectItem(item)"
        >
          {{ item.name }}
        </span>
      </span>
    </button>
  </div>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  props: ['baseDropDown'],
  emits: ['selectedItem'],
  setup(props, { emit }) {
    const config = ref(props.baseDropDown);
    const show = ref(false);
    const selectedItem = ref(null);

    const dropDownId = `dropdown-${ Math.floor(Math.random() * 1000) }`;
    const optionsId = `option-${ Math.floor(Math.random() * 1000) }`;

    const selectItem = (item) => {
      const btns = document.querySelectorAll('.drop-down-select')
      selectedItem.value = item;
      emit('selectedItem', item);

      if (btns) {
        for (let element of btns) {
          element.blur();
          setTimeout(() => show.value = false, 100);
        }
      }
    }

    const getModelData = () => {
      const getObjModel = config.value.options.find((i) => i.value === config.value.model);
      selectItem(getObjModel);
    }

    watch(() => config.value.options, () => {
      config.value.model ? getModelData() : selectedItem.value = null;
    })

    if (config.value.model) {
      getModelData();
    }

    watch(
      () => config.value.model,
      () => {
        getModelData();
      }
    )

    return {
      config,
      show,
      selectedItem,
      optionsId,
      dropDownId,
      selectItem,
      isFocus: () => {
        const dropdown = document.getElementById(dropDownId);
        const dropdownContent = document.getElementById(optionsId);
        const dropdownRect = dropdown.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        show.value = !show.value;

        if ((dropdownRect.bottom + dropdown.clientHeight + 142) >= windowHeight) {
          dropdownContent.style.top = '-142px';
        }
      },
      isBlur: () => {
        show.value = false;
      }
    }
  }
}
</script>
