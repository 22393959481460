import { errorCodeObject } from "@/entities";
import shared from '@/shared';
const { SportData } = shared.api;
const { sportsbookFilterObject } = shared.model;

const state = {
  sports_list: [],
  selected_sport_id: null
};

const getters = {
  GET_SELECTED_ID(state) {
    return state.selected_sport_id
  },
  GET_SPORT_LIST(state) {
    return state.sports_list;
  }
};

const mutations = {
  SET_SELECTED_SPORT_ID(state, id) {
    state.selected_sport_id = id;
  },
  SET_SPORTS_LIST(state, data) {
    state.sports_list = data;
  }
};

const actions = {
  SPORT_LIST_DATA(context, newKeys) {
    SportData.getSportList({
      filter: sportsbookFilterObject(newKeys),
      paging: {
        currentPage: 0,
        itemsPerPage: 100
      }
    })
      .then(({data}) => {
        context.commit('SET_SPORTS_LIST', data.data);
      })
      .catch(err => {
        errorCodeObject(err);
      })
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
