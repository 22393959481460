<template>
<!-- class="cut-text" -->
  <button
    :class="config.variant || ' '"
    :disabled="config.disabled"
    @click="config.func()"
    :style="config.style"
    :title="config?.title ? config.title : ''"
    v-if="config.show != null ? config.show : true"
  ><!-- v-if="config.show != null ? config.show : true" -->
    <svg v-if="config.icon" fill="#fff" width="20" height="20">
      <use :href="config.icon"></use>
    </svg>
    {{ config.text }}
    <slot name="button-body"></slot>
  </button>
</template>

<script>
  import { ref, watch } from 'vue';

  export default {
    props: ['baseBtnConfig'],
    setup(props) {
      const config = ref(props.baseBtnConfig);

      watch(() => props.baseBtnConfig, () => {
        config.value = props.baseBtnConfig;
      })
      
      return {
        config
      }
    }
  }
</script>
