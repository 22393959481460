import shared from '@/shared';
import { errorCodeObject } from '@/entities';

const { Cashdesk, SportData } = shared.api;

import { formatEventData } from './formatEventData';

const state = {
  searched_data: null,
  event_data: null,
  selected_event_id: null
};

const getters = {
  GET_SEARCHED_DATA(state) {
    return state.searched_data;
  },

  GET_EVENT_DATA(state) {
    return state.event_data
  },
  GET_SELECTED_EVENT_ID(state) {
    return state.selected_event_id;
  }
};

const mutations = {
  SET_SEARCHED_DATA(state, data) {
    state.searched_data = data;
  },

  SET_EVENT_DATA(state, data) {
    state.event_data = formatEventData(data, state.event_data);
  },
  SET_SELECTED_EVENT_ID(state, id) {
    state.selected_event_id = id;
  }
};

const actions = {
  async FIND_EVENT_BY_TAG(context, tag) {
    context.commit('SET_SEARCHED_DATA', null)
    context.commit('SET_SELECTED_EVENT_ID', null);
    const cashdeskData = context.rootGetters['balanceState/BALANCE'];
    const cashdeskId = cashdeskData.id.split('.');
    cashdeskId.length = 2;
    const coId = cashdeskId.join('.');

    const requestData = {
      coId,
      retailSportTagId: tag
    }

    try {
      const { data } = await Cashdesk.getShortEventTag(requestData);
      context.commit('SET_SELECTED_EVENT_ID', data.eventId);
      await context.dispatch('GET_EVENT_BY_ID', data.eventId)
    } catch(err) {
      context.commit('SET_SELECTED_EVENT_ID', tag);
      await context.dispatch('GET_EVENT_BY_ID', tag);
    }
  },
  async GET_EVENT_BY_ID(context, id) {
    try {
      const { data } = await SportData.getEventData(id);
      if (data) {
        context.commit('SET_EVENT_DATA', data);
      }
    } catch(err) {
      context.commit('SET_EVENT_DATA', null);
      context.commit('SET_SEARCHED_DATA', null);
      context.commit('SET_SELECTED_EVENT_ID', null);
      errorCodeObject(err)
    }
  },
  async GET_EVENTS_BY_NAME(context, name) {
    const { data } = await SportData.searchByName(name);

    const eventArr = data.reduce((arr, item) => {
      arr.push({
        tournamentName: item.tournament,
        name: item.event,
        id: item.eventId,
        startTime: item.eventStartDate
      })
      return arr;
    }, [])

    context.commit('SET_SEARCHED_DATA', eventArr);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
