import axios from 'axios';
import config from '@/shared/api/config/config.js';

const retailSystemInstance = axios.create(config.init('bo-retail/bo'));

retailSystemInstance.interceptors.request.use(config.request.success, config.request.failure);

retailSystemInstance.interceptors.response.use(config.response.success, config.response.failure);

export default class BackOffice {
  getLangs(obj){
    return retailSystemInstance.post(`/dictionary-service/languages:find`, obj)
  }
}
