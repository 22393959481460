import shared from '@/shared';
const { BackOffice } = shared.api;

import { getCMSConfig } from './getCMSConfig.js';
import { localGetLogo } from './localGetLogo.js';
import { canvasBlackLogo } from './canvasBlackLogo.js';

const state = {
  language: null,
  languages: [],
  logo: null,
  header_logo_src: '',
  switch_monitor_view: false,
  odds_format: 'decimal',
  print_logo_url: '',
  theme: true
};

const getters = {
  ODDS_FORMAT(state) {
    return state.odds_format;
  },
  LANGUAGE(state) {
    return state.language;
  },
  LANGUAGES(state) {
    return state.languages;
  },
  GET_LOGO(state) {
    return state.logo;
  },
  HEADER_LOGO_SRC(state) {
    return state.header_logo_src;
  },
  SWITCH_MONITOR_VIEW(state) {
    return state.switch_monitor_view;
  },
  GET_PRINT_LOGO_URL(state) {
    return state.print_logo_url;
  },
  GET_THEME(state) {
    return state.theme;
  }
};

const mutations = {
  ODDS_FORMAT(state, value) {
    state.odds_format = value;
  },
  ADD_LOGO(state, img) {
    state.logo = img;
  },
  ADD_LANGS(state, langs) {
    state.languages = langs;
  },
  SET_HEADER_LOGO_SRC(state, src) {
    state.header_logo_src = src;
  },
  SET_NEW_LANG(state, lang) {
    state.language = lang;
  },
  CHANGE_SWITCH_MONITOR_VIEW(state, bool) {
    state.switch_monitor_view = bool;
  },
  SET_PRINT_LOGO_URL(state, url) {
    state.print_logo_url = url;
  },
  SET_THEME(state, type) {
    state.theme = type;
  }
};

const actions = {
  async GET_LOGO(context) {
    const cmsData = await getCMSConfig();
    const cdnLogo = cmsData.logo_desktop_light;
    const printedLogo = cmsData.logo_printed_betslip;
    const cdn = context.rootGetters['CDN_URL'];
    context.commit('SET_HEADER_LOGO_SRC', `${ cdn }/${ cdnLogo }`);

    const printLogoUrl = `${ cdn }/${ printedLogo }`;

    context.commit('SET_PRINT_LOGO_URL', printLogoUrl);

    const appType = context.rootGetters['APP_TYPE'];
    let imgData;
    if (appType === 'terminal') {
      try {
        const base64 = await window.Android.proxyGetBase64(printLogoUrl);
        imgData = atob(base64);
      } catch(err) {
        console.error(err);
      }
    } else {
      imgData = await localGetLogo(printLogoUrl);
    }

    // IMPORTANT FOR DEBUGGENC LOCAL LOGO
    // const imgData = await require('../../../shared/assets/test.svg');
    // const resp = await fetch(imgData);
    // const text = await resp.text();
    // console.log('img', imgData, text);

    if (imgData?.includes('svg')) {
      canvasBlackLogo(imgData, context)
    }
  },
  GET_LANGS(context) {
    const projectId = context.rootGetters['PROJECT_ID'];
    const params = {
      filter: {
        enabled: true,
        title: null,
        code: null,
        partnerId: 1,
        projectId
      },
      paging: {
        itemsPerPage: 100,
        currentPage: 0,
        totalItems: 0,
        totalPages: 0
      }
    }

    BackOffice.getLangs(params).then(response => {
      context.commit('ADD_LANGS', response.data.data);
    })
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
