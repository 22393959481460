import BackOffice from './bo';
import Booking from './public';
import Ordering from './ordering';
import Login from './login';
import Local from './local';
import CashdeskLogin from './cashdesk-login';
import SportData from './sport-data';
import Cashdesk from './cashdesk';
import CMS from './cms';
import RetailPublic from './retail-public';
import PublicApi from './public-api';

export default {
    BackOffice,
    Booking,
    Ordering,
    Login,
    Local,
    CashdeskLogin,
    SportData,
    Cashdesk,
    CMS,
    RetailPublic,
    PublicApi
}
