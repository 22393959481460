import { store } from '@/app/store/store';

export default {
  init(url){
    return {
      baseURL: `${process.env.VUE_APP_RETAIL_GATEWAY_URL}/${url}`,
      timeout: 25000,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    }
  },
  request: {
    success(config){
      store.commit('SET_LOADER', true);
      const { sessionToken, sessionProject } = store.getters['cashierStore/AUTH'];
      config.headers.Authorization = `bearer ${ sessionToken }`;
      store.commit('SET_PROJECT_ID', sessionProject);
      config.headers['x-project-id'] = sessionProject
      return config;
    },
    failure(err){
      store.commit('SET_LOADER', false);
      return Promise.reject(err);
    }
  },
  response: {
    async success(response){
      await new Promise((resolve) => setTimeout(resolve, 0)); // ожидание следующего цикла событий
      store.commit("SET_LOADER", false);
      store.commit("SET_LOADER_DISABLE", false);
      return response;
      
      // console.log("success", response)
      // store.commit("SET_LOADER", false);
      // store.commit("SET_LOADER_DISABLE", false);
      // return response;
    },
    failure(err){
      store.commit("SET_LOADER", false);
      store.commit("SET_LOADER_DISABLE", false);
      return Promise.reject(err);
    }
  }
}
