import { store } from '@/app/store/store.js';
import i18n from '@/app/i18n.js';

const errorCodeObject = (errorBody) => {
  const { response } = errorBody;
  const codeNumber = errorBody.message.replace(/[^\d]/g, '')

  const errBody = () => {
    let strHead;
    let strBody;
    switch(response.data.status | codeNumber) {
      case 404:
        strHead = i18n.global.t('service_unavailable');
        strBody = response.data.description.replace(/[^\w\s]/g, ' ');
        break;
      case 412:
        strHead = i18n.global.t('service_unavailable');
        strBody = response.data.message.indexOf('_') > 0
          ? i18n.global.t(response.data.message)
          : response.data.message === 'No active session found' ? i18n.global.t('no_active_session') : response.data.message.replace(/[^.\w]/g, ' ');
        break;
      case 500:
        strHead = i18n.global.t('service_unavailable')
        strBody = i18n.global.t('error_500');
        break;
      case 401:
        strHead = i18n.global.t('session_expired');
        strBody = i18n.global.t('error_401_403');
        break;
      case 403:
        strHead = i18n.global.t('session_expired');
        strBody = i18n.global.t('error_401_403');
        break;
      case 503:
        strHead = i18n.global.t('session_expired');
        strBody = i18n.global.t('error_503');
        break;
      case 522:
        strHead = i18n.global.t('connection_time_out');
        strBody = i18n.global.t('error_522');
        break;
      default:
        strHead = i18n.global.t('error');
        strBody = i18n.global.t('error_default');
        break;
    }
    return { head: strHead, body: strBody };
  }

  store.commit('notificationsStore/ADD_ERROR_DATA', {
    header: errBody().head,
    body: errBody().body,
    code: response.data.status | codeNumber
  });
}

export { errorCodeObject }
