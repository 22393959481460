import uiTabs from './ui-tabs';
import uiTable from './ui-tabel';
import uiLoader from './ui-loader';
import uiInput from './ui-input';
import uiDropdown from './ui-dropdown';
import uiCheckbox from './ui-checkbox';
import uiButtonFactory from './ui-button-factory';
import uiButton from './ui-button';
import loadingText from './loading-text';
import uiCard from './ui-card';
import uiBigButton from './ui-big-button';
import uiPopup from './ui-popup';
import uiErrorPopup from './ui-error-popup'
import uiDialog from './ui-dialog';
import uiSuccessfulPopup from './ui-successful-popup';
import uiSearch from './ui-search';
import uiSmSpinner from './ui-sm-spinner';
import uiNavbar from './ui-navbar';

export default {
    uiTabs,
    uiTable,
    uiLoader,
    uiInput,
    uiDropdown,
    uiCheckbox,
    uiButtonFactory,
    uiButton,
    loadingText,
    uiCard,
    uiBigButton,
    uiPopup,
    uiErrorPopup,
    uiDialog,
    uiSuccessfulPopup,
    uiSearch,
    uiSmSpinner,
    uiNavbar
}
