import { errorCodeObject } from "@/entities";
import shared from '@/shared';
const { SportData, RetailPublic } = shared.api;
const { sportsbookFilterObject } = shared.model;

const state = {
  tournaments_list: [],
  selected_tournament_id: null,
  sport_forms: null,
  selected_sport_form_id: null,
  top_tournaments: [],
  top_tournaments_view: null
};

const getters = {
  TOP_TOURNAMENTS(state) {
    return state.top_tournaments;
  },
  GET_SELECTED_TOURNAMENT_ID(state) {
    return state.selected_tournament_id
  },
  GET_TOURNAMENTS_LIST(state) {
    return state.tournaments_list;
  },
  GET_SPORT_FORMS(state) {
    return state.sport_forms
  },
  GET_SELECTED_SPORT_FORM_ID(state) {
    return state.selected_sport_form_id;
  },
  TOP_TOURNAMENTS_VIEW(state) {
    return state.top_tournaments_view;
  }
};

const mutations = {
  SET_SELECTED_TOURNAMENT_ID(state, id) {
    state.selected_tournament_id = id;
  },
  SET_TOURNAMENTS_LIST(state, data) {
    state.tournaments_list = data;
  },
  SET_SPORT_FORMS(state, data) {
    state.sport_forms = data.bySportFormId;
  },
  SET_SELECTED_SPORT_FORM_ID(state, id) {
    state.selected_sport_form_id = id;
  },
  TOP_TOURNAMENTS(state, tournaments) {
    state.top_tournaments = tournaments;
  },
  TOP_TOURNAMENTS_VIEW(state, bool) {
    state.top_tournaments_view = bool;
  }
};

const actions = {
  async LOAD_TOP_TOURNAMENTS(context, showTC) {
    const resp = await SportData.getTournamentList({
      filter: {
        isTop: true,
        sportServices:['PREMATCH','LIVE']
      },
      paging: {
        currentPage:0,
        itemsPerPage:100
      }
    })

    context.commit('TOP_TOURNAMENTS', resp.data.data);

    if (showTC) {
      localStorage.setItem('showTC', true);
      context.commit('TOP_TOURNAMENTS_VIEW', true);
    }
  },
  TOURNAMENT_LIST_DATA(context, newKeys) {
    SportData.getTournamentList({
      filter: sportsbookFilterObject(newKeys),
      paging: {
        currentPage: 0,
        itemsPerPage: 100
      }
    })
      .then(({data}) => {
        context.commit('SET_TOURNAMENTS_LIST', data.data);
      })
      .catch(err => {
        errorCodeObject(err);
      })
  },
  async SPORT_FORMS(context, request) {
    const { data } = await RetailPublic.getSportForm(request);
    context.commit('SET_SPORT_FORMS', data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
