import axios from 'axios';
import config from '@/shared/api/config/config.js';
import { store } from '@/app/store/store';

const retailSystemInstance = axios.create(config.init('bo-retail/retail-service/'));

retailSystemInstance.interceptors.request.use(config.request.success, config.request.failure);

retailSystemInstance.interceptors.response.use(config.response.success, config.response.failure);

export default class Cashdesk {
  getShiftStatistic() {
    return retailSystemInstance.get('cashier-shift:statistic');
  }
  getShortEventTag(data) {
    return retailSystemInstance.post(`sport-tag:get`, data);
  }
  getCashdeskList(id) {
    return retailSystemInstance.get(`/cashier/cash-desk-list/${ id }`);
  }
  sendBet(data = {}) {
    return retailSystemInstance.post('/bets:placeAsync', data);
  }
  getBetResponse(requestId) {
    return retailSystemInstance.get(`/bets/${ requestId }`);
  }
  balanceData(data = {}) {
    return retailSystemInstance.post('balance:info', data);
  }
  findCoupon(id) {
    return retailSystemInstance.get(`/payout/bet/${ id }`)
  }
  payout(data = {}) {
    return retailSystemInstance.post('/bet:payout', data);
  }
  payoutDep(data = {}) {
    return retailSystemInstance.post('/bet:payout-deposit:create', data);
  }
  cashierHistory(filter) {
    return retailSystemInstance.post('/balance-cashier:history', filter)
  }
  confirmTransactions(id) {
    return retailSystemInstance.post(`/money-transfer/point:complete/${ id }`)
  }
  transferData(filter) {
    return retailSystemInstance.post('money-transfer/cashdesck:find', filter);
  }
  transferCashOut(sum) {
    return retailSystemInstance.post('money-transfer/cashdesk/route:create', sum);
  }
  clientDeposit(data) {
    return retailSystemInstance.post('/cashier/client-deposit', data);
  }
  clientInfo(id) {
    return retailSystemInstance.get(`/cashier/client-info/${ id }`);
  }
  canBePrinted(id) {
    return retailSystemInstance.get(`/printer/${ id }`)
  }
  changeCanBePrinted(data = {}) {
    return retailSystemInstance.post('/printer/status:print', data);
  }
  completePreOrder(id) {
    return retailSystemInstance.post(`pre-order/complete/${ id }`)
  }
  getBetData(betId) {
    return retailSystemInstance.get(`bet:short/${ betId }`)
  }
  withdrawInfo(id) {
    return retailSystemInstance.post('client-deposit/withdraw/request:info', { correlationId: id });
  }
  getMaxBet(betData = {}) {
    return retailSystemInstance.post('limits:max-bet', betData);
  }
  cancelBet(data, cashdeskId) {
    const config = {
      headers: {
        'x-cashdesk-id': cashdeskId,
      }
    }
    return retailSystemInstance.post('bet:cancel', data, config);
  }
  withdrawComplete(id) {
    const cashdescData = store.getters['cashdeskDataStore/GET_CASHDESK_DATA'];
    return retailSystemInstance.post(
        'client-deposit/withdraw/request:complete',
        { correlationId: id },
        {
          headers: {
            'x-cashdesk-id': cashdescData.id
          }
        }
    );
  }
}
