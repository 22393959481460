<template>
  <table class="base-table_table">
    <thead class="base-table_thead">
      <tr>
        <th v-for="(head, index) in tableArr.head" :key="index">
          {{ head }}
        </th>
      </tr>
    </thead>
    <tbody class="base-table_tbody">
      <tr v-for="(trItem, trIndex) in tableArr.body" :key="trIndex">
        <td v-for="(tdItem, tdIndex) in trItem" :key="tdIndex">
          <template v-if="Array.isArray(tdItem)">
            <div class="flex-between">
              <ui-button-factory :buttonFactoryConfig="tdItem" />
            </div>
          </template>
          <template v-else>
            {{ tdItem }}
          </template>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { ref } from "vue";

export default {
  props: ['tableData', 'columns'],
  setup(props) {
      const tableArr = ref(null);

      const formatAllData = () => {
        const headArr = props.columns.map(i => i.head);

        const arr = Array.isArray(props.tableData) ? props.tableData : Object.values(props.tableData);

        let valuesArr;
        try {
          valuesArr = arr.reduce((arr, item) => {
            const trItems = props.columns.map(colum => {
              let btnConfig;
              if (colum.button) {
                btnConfig = colum.button.map(ib => {
                  return {
                    text: ib.text,
                    variant: ib.variant,
                    func: ib.func.bind(null, item)
                  };
                })
              }
              return !colum.button ? item[colum.value] : btnConfig
            })
            arr.push(trItems);
            return arr;
          }, [])
        } catch (err) {
          console.log(err)
        }

        return {
          head: headArr,
          body: valuesArr
        }
      }

    tableArr.value = formatAllData();

    return {
      tableArr
    }
  }
}
</script>
