const store = require('@/app/store/store.js');
const JsBarcode = require('jsbarcode');

let default_w = 500;
let default_h = 500;

let sy = 30;
let sx = 15;

let fontSize = 0.04; //was 0.03
let fontSizeSmall = 0.03;
let titleFont = 0.05; // was 0.04
let standardPadding = 30;

export function drawTicket(data, redraw = true, height){
	if(!redraw) {
		sy = 30;
	}
	if(!data) {
		throw {msg: "Missing data to print"};
	}
	let canvas = createCanvas(default_w, calculateHeight(height));
	let ctx = canvas.getContext('2d');

	if(store.store.getters['APP_TYPE'] == 'terminal') {
		canvas.width = default_w * 0.75;
		canvas.height = height;
	}

	ctx.fillStyle = "white";
	ctx.fillRect(0, 0, canvas.width, canvas.height);
	const fontStyle = {
		fontBold: "bold " + (Math.round(ctx.canvas.width * fontSize)) + "px Arial",
		titleBold: "bold " + (ctx.canvas.width * titleFont) + "px Arial",
		fontNormal: "normal " + (Math.round(ctx.canvas.width * fontSize)) + "px Arial",
		fontSmall: "normal " + (Math.round(ctx.canvas.width * fontSizeSmall)) + "px Arial",
	};

	const rows = data;

	rows.forEach(row => {
		switch (row.type) {
			case 'text':
				drawText(ctx, row.content, sx * row.xPadding, sy += row.prevContentHeight ? row.prevContentHeight + (standardPadding * row.yPadding) : (standardPadding * row.yPadding), fontStyle[row.font], row.position, row.sameRowData || null)
				break;
				case 'logo':
					// eslint-disable-next-line
					const logoObj = row.content;
					// eslint-disable-next-line
					const coef = ctx.canvas.width * 0.75 / logoObj.width;

					ctx.drawImage(logoObj.logo, (ctx.canvas.width - logoObj.width * coef) / 2 , sy += (standardPadding * row.yPadding), logoObj.width * coef, logoObj.height * coef);
					sy += logoObj.height * coef;
					break;
			case 'barcode':
				// drawPdf417(ctx, row.content, sx * row.xPadding, sy + (standardPadding * row.yPadding)); // old
				// eslint-disable-next-line
				const tempCanvas = document.createElement('canvas');
				JsBarcode(tempCanvas, row.content, {
					format: "code39",
					displayValue: false
				});

				ctx.drawImage(tempCanvas, (ctx.canvas.width - tempCanvas.width)/2, sy + (standardPadding * row.yPadding));
				break;
			case 'line':
				ctx.beginPath();
				ctx.moveTo(sx, sy += standardPadding * row.yPadding);
				ctx.lineTo(ctx.canvas.width - sx, sy);
				ctx.stroke();
				break;
		}
	});

	if(redraw) {
		ctx.clearRect(0,0,canvas.width,canvas.height)
		return drawTicket(rows, false, sy + 30)
	} else {
		let image = convertCanvasToImage(canvas);
		// deleteElement("#draw-canvas");
		sy = 30;
		return image;
	}
}

function calculateHeight(height) {
	return height || default_h;
}

function createCanvas(w, h, id) {
	let canvasElement = window.document.createElement('canvas');
	canvasElement.width = w ;
	canvasElement.height = h;
	canvasElement.id = id || 'draw-canvas';
	//window.document.body.appendChild(canvasElement);
	return canvasElement;
}

function drawText(ctx, msg, x, y, font, position, sameLineEl) {
	ctx.font = font;
	let positionX;
	let partsOfMsg = [];
	let sameLineStringSize = sameLineEl ? ctx.measureText(sameLineEl).width : 0;
	let canvasRealWidth = ctx.canvas.width - sx*2 - sameLineStringSize;

	if(ctx.measureText(msg).width > canvasRealWidth) {
		let words = msg.split(' ');
		let part = '';

		//['Andrey', 'BetShop,', '1,', 'Prospekt', 'Peremohy,', 'Kyiv']
		for(let i in words){
			const word = words[i];

			if((ctx.measureText(part).width + ctx.measureText(' ' + word).width) > canvasRealWidth) {
				partsOfMsg.push(part);
				part = word + ' ';

				if(+i === words.length - 1){
					part = part.trim();
					partsOfMsg.push(part);
					part = ''
				}
			} else {
				part += word + ' ';

				if(+i === words.length - 1){
					part = part.trim();
					partsOfMsg.push(part);
					part = '';
				}
			}
		}

		// console.log('partsOfMsg', partsOfMsg)

		partsOfMsg.forEach((part, i) => {
			switch(position){
				case "center":
					positionX = (canvasRealWidth - ctx.measureText(part).width)/2
					break;
				case "right":
					positionX = ctx.canvas.width - ctx.measureText(part).width - sx;
					break;
				default:
					positionX = sx;
			}
			let topPadding;
			if (i === 0) {
				topPadding = sy;
			} else {
				sy += standardPadding
				topPadding = sy;
			}

			drawShape(ctx, '#000', (c) => {
				c.textBaseline = "middle";
				c.fillText(part, positionX,  topPadding);
			});

			// drawText(ctx, part, sx, sy += standardPadding, font, position)
		})

	} else {
		switch(position){
			case "center":
				positionX = (ctx.canvas.width - ctx.measureText(msg).width)/2
				break;
			case "right":
				positionX = ctx.canvas.width - ctx.measureText(msg).width - x;
				break;
			default:
				positionX = x;
		}

		drawShape(ctx, '#000', (c) => {
			c.textBaseline = "middle";
			c.fillText(msg, positionX,  y);
		});
	}
}

function splitCanvasIntoChunks(canvas, chunkSize) {
  const chunks = [];
  let y = 0;

  while (y < canvas.height) {
    const chunkCanvas = document.createElement('canvas');
    chunkCanvas.width = canvas.width;
    chunkCanvas.height = Math.min(chunkSize, canvas.height - y);

    const chunkContext = chunkCanvas.getContext('2d');
    chunkContext.drawImage(canvas, 0, -y);

    chunks.push(chunkCanvas.toDataURL());

    y += chunkSize;
  }

  return chunks;
}

function convertCanvasToImage(canvas) {
	if(store.store.getters['APP_TYPE'] === 'terminal') {
		let image = new Image();

		image.src = canvas.toDataURL("image/png");

		return image;
	} else {
		const optimalChunkSize = 2000;
		const chunks = splitCanvasIntoChunks(canvas, optimalChunkSize);

		return chunks;
	}
}

// function deleteElement(selector) {
// 	let el = window.document.querySelector(selector);
// 	el.parentNode.removeChild(el);
// }

function drawShape(ctx, color, func) {
	ctx.beginPath();
	setStyleColor(ctx, color);
	func(ctx);
	ctx.fill();
	ctx.closePath();
}

function setStyleColor(ctx, color) {
	ctx.fillStyle = color;
	ctx.strokeStyle = color;
}
