import axios from 'axios';
import config from '@/shared/api/config/config.js';

const retailSystemInstance = axios.create(config.init('bo-retail/retail-gateway/'));

retailSystemInstance.interceptors.request.use(config.request.success, config.request.failure);

retailSystemInstance.interceptors.response.use(config.response.success, config.response.failure);

export default class CashdeskLogin {
  goLogin(data = {}) {
    return retailSystemInstance.post('/cashdesk:login', data);
  }
  goLogout() {
    return retailSystemInstance.post('/cashdesk:logout')
      .catch((err) => console.log(err));
  }
}
