import { store } from '@/app/store/store.js';

import i18n from '@/app/i18n.js';
const {t} = i18n.global;

import Local from '@/shared/api/local/local.js';
const local = new Local();
import { drawTicket } from '@/shared/model/ticket-printer/ticketPrinter.js';

const CryptoJS = require('crypto-js');

function encryptTextWithSalt(text, password) {
  const encrypted = CryptoJS.AES.encrypt(text, password);
  return encrypted
}

export function couponPrint({printData, type}) {
    const betId = localStorage['betId'] ? JSON.parse(localStorage['betId']) : 1;
    const data = drawTicket(printData);
    const { sessionToken } = store.getters['cashierStore/AUTH'];

    // IMPORTANT FOR FUTURE TEXT PRINTING
    // function textPrintFormat() {
    //     const requestObj = {
    //         betId,
    //         lines: printData.map((line, i) => {
    //             return {
    //                 ...line.textPrintingSettings,
    //                 feed: line.yPadding,
    //                 type: line.type !== 'barcode' && line.type !== 'logo' ? line.type.toUpperCase() : 'IMAGE',
    //                 order: i
    //             }
    //         }),
    //         retailServiceUrl: window.location.origin,
    //         token: sessionToken
    //     }
    //     return requestObj
    // }

    if (store.getters['APP_TYPE'] === 'terminal') {
        const str = data
            .getAttribute('src')
            .replace(new RegExp('data:image/png;base64,', 'g'), '');
        try {
            window.Android.printBase64(str);
        } catch(err) {
            console.log('err', err)
        }
        store.commit('couponStore/CLEAR_COUPON_DATA');
    } else {
        // for test
        const fullImg = data.map((baseStr) => baseStr.replace(new RegExp('data:image/png;base64,', 'g'), '')).join('');
        console.log('fullImg', fullImg);

        const lines = data.map((baseStr, i) => ({
            data: type === 'placeBet'
                ? encryptTextWithSalt(baseStr.replace(new RegExp('data:image/png;base64,', 'g'), ''), sessionToken).toString()
                : baseStr.replace(new RegExp('data:image/png;base64,', 'g'), ''),
            type: 'IMAGE',
            order: i
        }))

        const api = type === 'placeBet' ? local.localPrinterPrintBet : local.localPrinterPrint;

        const req = {
            lines,
            retailServiceUrl: window.location.origin,
            token: sessionToken
        }

        if (type === 'placeBet') {
            req.betId = betId;
        } else {
            delete req.token;
            delete req.retailServiceUrl;
        }

        store.commit('couponStore/CLEAR_COUPON_DATA');

        api(req)
            .then(resp => {
                if (resp.data.success) {
                    store.commit('notificationsStore/ADD_ALERTS_DATA', resp.data.message);
                    setTimeout(() => {
                        store.commit('notificationsStore/ADD_ALERTS_DATA', null);
                    }, 1000);
                } else {
                    store.commit('notificationsStore/ADD_ERROR_DATA', {
                        header: t('error'),
                        body: resp.data.message
                    });
                }
            })
            .catch(() => {
                store.commit('notificationsStore/ADD_ERROR_DATA', {
                    header: t('error'),
                    body: t('please_check_if_the_printer_app_is_started')
                });
            })
    }
}
